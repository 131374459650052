import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  AudioAnalysisStreamProvider,
  Box,
  FilePicker,
  GridContainer,
  GridItem,
  Icon,
  MediaProviderVideo,
  type PiPAnchor,
  type PiPSize,
  ScreenRecordingBar,
  Typography,
  styled,
  useCanvasPainter,
  useDisplayMediaStream,
  useDisplayMediaStreamSurface,
  useDocumentVisible,
  useI18n,
  useInterval,
  useMediaStreamActive,
  useMediaStreamRecorder,
  useMergedMediaStream,
  useMuteMediaStream,
  usePersistedState,
  useSilentBackgroundAudio,
  useUserMediaStream,
  useVideoFromMediaStream,
} from '@cofenster/web-components';

import { useCaptureAssetCandidateFileContext } from '../../../../../context/captureAssetFile';
import { useDialogs } from '../../../../../context/dialogs/useDialogs';
import { useMediaDevice } from '../../../../../hooks/media/useMediaDevice';
import { useTrackRecordSettingUpdated } from '../../../../../hooks/useTrackRecordSettingUpdated';
import type { DeleteRecordingDialogProps } from '../../../../dialog';
import { FormatAwareContentArea } from '../../../../layout';
import { CallToActionRequiredWithDropzone } from '../../../CallToActionRequiredWithDropzone';
import { useCaptureAssetLifecycleFlow } from '../../../CaptureAssetLifecycleFlow';
import { InfoBanner } from '../../../InfoBanner';
import { useStartRecordingWithNoMicInfo } from '../../useStartRecordingWithNoMicInfo';

const usePIPStream = (
  desktop: MediaStream | null,
  camera: MediaStream | null,
  allowOnlyCamera = false,
  fps = 10,
  defaultVideoFormat: VideoFormat = 'Horizontal',
  pipSize?: PiPSize,
  pipAnchor?: PiPAnchor
) => {
  const shouldUseCanvas = useMemo(() => {
    if (desktop && camera) return true;
    if (desktop) return false;
    return !!(camera && allowOnlyCamera);
  }, [desktop, camera, allowOnlyCamera]);

  useSilentBackgroundAudio(shouldUseCanvas);

  const desktopVideo = useVideoFromMediaStream(shouldUseCanvas ? desktop : null, defaultVideoFormat);
  const canvasPainter = useCanvasPainter(
    useMemo(() => (shouldUseCanvas ? desktopVideo : null), [shouldUseCanvas, desktopVideo])
  );

  const cameraVideo = useVideoFromMediaStream(canvasPainter ? camera : null, defaultVideoFormat);

  const paint = useMemo(() => {
    if (canvasPainter) {
      if (desktopVideo && cameraVideo)
        return () => {
          canvasPainter.paint(desktopVideo);
          canvasPainter.pip(cameraVideo, pipSize, pipAnchor);
        };
      if (cameraVideo)
        return () => {
          canvasPainter.clear();
          canvasPainter.pip(cameraVideo, pipSize, pipAnchor);
        };
    }
    return null;
  }, [canvasPainter, desktopVideo, cameraVideo, pipAnchor, pipSize]);

  useInterval(paint, 1000 / fps);

  const canvasStream = useMemo(() => canvasPainter?.mediaStream() ?? null, [canvasPainter]);

  return canvasStream ?? desktop;
};

export const CoCaptureScreenRecording: FC = () => {
  const documentVisible = useDocumentVisible();

  const [availableMicrophones, microphone, setMicrophone] = useMediaDevice('audioinput', 'first');
  const microphoneConstraints = useMemo(() => (microphone ? { video: false, audio: microphone } : null), [microphone]);
  const { value: microphoneStream } = useUserMediaStream(microphoneConstraints);
  const [muted, setMuted] = useMuteMediaStream(microphoneStream);
  const { translate } = useI18n();
  const { format: videoFormat } = useCaptureAssetLifecycleFlow();
  const [pipSize, setPiPSize] = usePersistedState<PiPSize>('preferred.pip.size', 'medium');
  const [pipAnchor, setPiPAnchor] = usePersistedState<PiPAnchor>('preferred.pip.anchor', 'bottom-right');

  useEffect(() => {
    if (microphone) setMuted(false);
  }, [microphone, setMuted]);

  const [availableCameras, camera, setCamera] = useMediaDevice('videoinput', 'none');
  const cameraConstraints = useMemo(() => (camera ? { video: camera, audio: false } : null), [camera]);
  const { value: cameraStream } = useUserMediaStream(cameraConstraints);
  const [desktopConstraints, setDesktopConstraints] = useState<MediaStreamConstraints | null>(null);
  const { value: desktopStream } = useDisplayMediaStream(desktopConstraints);
  const desktopSurface = useDisplayMediaStreamSurface(desktopStream);
  const desktopCapture = usePIPStream(desktopStream, cameraStream, false, 10, videoFormat, pipSize, pipAnchor);

  const recordableStream = useMergedMediaStream(desktopCapture, microphoneStream);
  const mediaStreamRecorder = useMediaStreamRecorder(recordableStream);

  const microphoneActive = useMediaStreamActive(microphoneStream);
  const cameraActive = useMediaStreamActive(cameraStream);
  const desktopActive = useMediaStreamActive(desktopStream);

  useTrackRecordSettingUpdated(desktopSurface, pipSize, pipAnchor);

  const stopCapture = useCallback(() => {
    setMicrophone(false);
    setCamera(false);
    setDesktopConstraints(null);
  }, [setMicrophone, setCamera]);

  useEffect(() => {
    if (microphoneActive === false) setMicrophone(false);
  }, [microphoneActive, setMicrophone]);

  useEffect(() => {
    if (cameraActive === false) setCamera(false);
  }, [cameraActive, setCamera]);

  useEffect(() => {
    if (desktopActive === false) {
      if (mediaStreamRecorder?.state === 'recording') {
        mediaStreamRecorder.stop();
      } else {
        // this was here to prevent black screen with recording UI
        // but caused screen recordings to be sometimes thrown away
        // stopCapture();
      }
    }
  }, [desktopActive, mediaStreamRecorder?.stop, mediaStreamRecorder?.state]);

  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();
  const onUpload = (file: File) =>
    onCaptureAssetReadyForReview(
      {
        url: URL.createObjectURL(file),
        blob: file,
      },
      { uploadSource: 'desktop-library' }
    );

  const { openDialog } = useDialogs();
  const openDeleteDialog = useCallback(
    (props: Pick<DeleteRecordingDialogProps, 'onAction'>) => openDialog('DeleteRecordingDialog', props),
    [openDialog]
  );

  const onDelete = useCallback(() => {
    if (mediaStreamRecorder) {
      if (mediaStreamRecorder.state === 'recording') {
        mediaStreamRecorder.pause();
      }

      openDeleteDialog({ onAction: stopCapture });
    }
  }, [mediaStreamRecorder, openDeleteDialog, stopCapture]);

  useEffect(() => {
    if (mediaStreamRecorder) {
      if (mediaStreamRecorder.state === 'inactive' && mediaStreamRecorder.bytesAvailable > 0) {
        const file = mediaStreamRecorder.asFile(translate('ScenePage.ScreenRecorder.filename'));
        onCaptureAssetReadyForReview(
          {
            url: URL.createObjectURL(file),
            blob: file,
          },
          {
            uploadSource: 'desktop-screen-recording',
            cameraEnabled: Boolean(camera),
            microphoneEnabled: Boolean(microphone),
          }
        );
        stopCapture();
      }
    }
  }, [
    mediaStreamRecorder,
    mediaStreamRecorder?.bytesAvailable,
    mediaStreamRecorder?.state,
    camera,
    microphone,
    onCaptureAssetReadyForReview,
    stopCapture,
    translate,
  ]);

  const { onStart } = useStartRecordingWithNoMicInfo(availableMicrophones, microphone, mediaStreamRecorder?.start);

  return (
    <>
      {recordableStream ? (
        <AudioAnalysisStreamProvider microphoneConstraints={microphoneConstraints}>
          <FormatAwareContentArea>
            <Box fullHeight backgroundColor="carbon">
              <ContainVideo
                autoPlay
                muted
                playsInline
                controls={false}
                srcObject={documentVisible ? recordableStream : null}
                data-testid="desktop-preview"
              />
              <ScreenRecordingBar
                isCompact={videoFormat !== 'Horizontal'}
                status={mediaStreamRecorder?.state ?? 'inactive'}
                recordingDuration={mediaStreamRecorder?.timeRecorded}
                onStart={onStart}
                onStop={mediaStreamRecorder?.stop}
                onPause={mediaStreamRecorder?.pause}
                onResume={mediaStreamRecorder?.resume}
                availableMicrophones={availableMicrophones}
                selectedMicrophone={microphone}
                selectMicrophone={setMicrophone}
                muted={muted}
                setMuted={microphone ? setMuted : undefined}
                availableCameras={availableCameras}
                selectedCamera={camera}
                selectCamera={setCamera}
                selectedDesktop={desktopSurface}
                selectDesktop={() => setDesktopConstraints({ video: true, audio: true })}
                onDelete={onDelete}
                pipAnchor={pipAnchor}
                pipSize={pipSize}
                setPipAnchor={setPiPAnchor}
                setPipSize={setPiPSize}
              />
            </Box>
          </FormatAwareContentArea>
          <GridContainer>
            <GridItem mt={2} xs={12}>
              <InfoBanner>
                {(Button) => (
                  <FilePicker
                    id="scene-video-upload"
                    data-testid="upload-button"
                    onFiles={(files) => files[0] && onUpload(files[0])}
                    video
                    ButtonComponent={Button}
                    variant="tertiary"
                    startIcon={<Icon type="UploadIcon" color="blue" />}
                  >
                    <Typography variant="l">i18n.Recording.VideoCapture.Upload</Typography>
                  </FilePicker>
                )}
              </InfoBanner>
            </GridItem>
          </GridContainer>
        </AudioAnalysisStreamProvider>
      ) : (
        <CallToActionRequiredWithDropzone
          type="screenRecordingStart"
          callToAction={() => setDesktopConstraints({ video: true, audio: true })}
        />
      )}
    </>
  );
};

const ContainVideo = styled(MediaProviderVideo)({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
