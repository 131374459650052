import { useCallback } from 'react';

import { useFileUpload } from '@cofenster/web-components';

import { Sentry } from '../../../sentry';
import { useAssetSizeLazyQuery, useInvalidateAssetMetadataCacheMutation } from '../../generated';
import { useInitImageUpload } from './useInitImageUpload';

export const useUploadImageAsset = () => {
  const initImageUpload = useInitImageUpload();
  const uploadFile = useFileUpload();

  const [getAssetSize] = useAssetSizeLazyQuery({ fetchPolicy: 'no-cache' });
  const [invalidateAssetMetadataCache] = useInvalidateAssetMetadataCacheMutation();

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      signal: AbortController['signal']
    ) => {
      const result = await initImageUpload();

      if (!result.data?.initImageUpload) throw new Error('Failed to init image upload');

      const { uploadUrl, imageAssetId } = result.data.initImageUpload;

      const uploadResult = await uploadFile(uploadUrl, file, uploadId, onProgress, signal);

      if (!uploadResult) {
        // Upload failed or was aborted
        return undefined;
      }

      const assetSizeResult = await getAssetSize({ variables: { id: imageAssetId } });
      const assetSize = assetSizeResult.data?.assetSize;

      if (!assetSize || assetSize.startsWith('0')) {
        Sentry.captureException(
          new Error(
            `The uploaded image asset (ID: ${imageAssetId}) has a file size of 0 bytes. Switching to XMLHttpRequest for the upload.`
          ),
          { level: 'error' }
        );
        const xhrUploadingResult = await uploadFile(uploadUrl, file, uploadId, onProgress, signal, 'XMLHttpRequest');
        await invalidateAssetMetadataCache({ variables: { id: imageAssetId } });
        if (!xhrUploadingResult) {
          // Upload failed or was aborted
          return undefined;
        }
      }

      return imageAssetId;
    },
    [initImageUpload, uploadFile, getAssetSize, invalidateAssetMetadataCache]
  );
};
